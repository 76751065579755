html, body {
  margin: 0;
}

body {
  color: #000;
  background: #e3e3e3;
  font-family: sans-serif;
}

a:link, a:visited {
  color: green;
  transition: all .15s ease-in-out;
}

a:hover {
  color: #00008b;
}

a:active {
  color: #639;
}

a:focus-visible {
  outline-offset: 1px;
  outline: 2px dashed #9acd32;
}

a[rel="external"] {
  position: relative;
}

a[rel="external"]:after {
  content: "⎋";
  color: #666;
  opacity: 0;
  width: 16px;
  height: 16px;
  font-size: 16px;
  transition: opacity .15s ease-in-out;
  display: inline-flex;
  position: absolute;
  top: -12px;
  right: -10px;
  transform: scaleX(-1);
}

a[rel="external"]:hover:after, a[rel="external"]:focus-visible:after {
  opacity: 1;
}

::selection {
  color: #000;
  background-color: #adff2f;
}

* {
  box-sizing: border-box;
}

#app {
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  display: flex;
}

.hello {
  color: #adff2f;
  text-shadow: 0 0 4px #adff2f99;
  caret-color: #0000;
  background-color: #000;
  border-radius: 1rem;
  outline: .5rem inset #ccc;
  width: fit-content;
  min-width: 20rem;
  max-width: calc(100vw - 2rem);
  margin: 0 auto;
  padding: 1rem;
  font-family: Fira Code, monospace;
  font-size: 1.1rem;
  position: relative;
  overflow: hidden;
}

@media (width >= 1024px) {
  .hello {
    min-width: 33vw;
    max-width: 66vw;
    min-height: 33vh;
    max-height: 90vh;
    margin-top: 10vh;
    padding: 2rem;
    font-size: 1.2rem;
  }
}

.hello * {
  display: inline;
}

.hello:before {
  content: "";
  pointer-events: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTI4MCIgaGVpZ2h0PSI4MzIiIHZpZXdCb3g9IjAgMCAxMjgwIDgzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjEyODAiIGhlaWdodD0iODMyIiBmaWxsPSJ1cmwoI3BhaW50MF9yYWRpYWxfMzM3XzMpIi8+CjxkZWZzPgo8cmFkaWFsR3JhZGllbnQgaWQ9InBhaW50MF9yYWRpYWxfMzM3XzMiIGN4PSIwIiBjeT0iMCIgcj0iMSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIGdyYWRpZW50VHJhbnNmb3JtPSJyb3RhdGUoMzMuMDIzOSkgc2NhbGUoMTUyNi42NCAyMzQ4LjY3KSI+CjxzdG9wIHN0b3AtY29sb3I9IndoaXRlIiBzdG9wLW9wYWNpdHk9IjAuMzMiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLW9wYWNpdHk9IjAiLz4KPC9yYWRpYWxHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K"), repeating-linear-gradient(#0000, #0000 3px, #00000050 3px 4px);
  background-size: cover;
  border-radius: 1rem;
  position: absolute;
  inset: 0;
}

.hello:focus-within:after {
  content: "█";
  animation: 1s infinite blonk;
}

.introduction {
  margin-bottom: 4rem;
  font-size: 1.2rem;
}

@keyframes blonk {
  0% {
    color: #000;
  }

  50% {
    color: #adff2f;
  }
}

fieldset {
  border: 1px solid #999;
  border-radius: 4px;
  padding: 1.5rem 1rem 1rem;
}

fieldset legend {
  color: #e3e3e3;
  background-color: #999;
  border: 1px solid #999;
  border-radius: 4px 0;
  margin-top: 13px;
  margin-left: -1rem;
  padding: .25rem .5rem;
  position: relative;
  top: 14px;
}

textarea {
  border: 1px solid #666;
  border-radius: 4px;
  padding: 1rem;
}

textarea:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #9acd32;
}

select {
  border: 1px solid #666;
  border-radius: 4px;
}

select:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #9acd32;
}

.checkbox-field {
  grid-template-columns: 1em auto;
  gap: .5em;
  line-height: 1.1;
  display: grid;
}

.checkbox-field input[type="checkbox"] {
  appearance: none;
  font: inherit;
  color: currentColor;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 4px;
  place-content: center;
  width: 1.15em;
  height: 1.15em;
  margin: 0;
  display: grid;
  transform: translateY(-.075em);
}

.checkbox-field input[type="checkbox"]:focus {
  outline: none;
}

.checkbox-field input[type="checkbox"]:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #9acd32;
}

.checkbox-field input[type="checkbox"]:before {
  content: "";
  transform-origin: 50%;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  width: .65em;
  height: .65em;
  transition: transform .12s ease-in-out;
  transform: scale(0);
  box-shadow: inset 1em 1em green;
}

.checkbox-field input[type="checkbox"]:checked:before {
  transform: scale(1);
}

.dropzone {
  border: 1px dashed #333;
  border-radius: 1rem;
  margin-bottom: 2rem;
  position: relative;
}

.dropzone:has(input[type="file"]:focus-visible) {
  outline-offset: 1px;
  outline: 2px solid #9acd32;
}

.dropzone label {
  text-align: center;
  padding: 1rem;
  display: block;
}

.dropzone input[type="file"] {
  opacity: 0;
  position: absolute;
  inset: 0;
}

.dropzone.has-file {
  border-color: green;
}

.dropzone .file-name {
  text-align: center;
  margin-top: 0;
}

.dropzone .remove {
  color: #cd5c5c;
  background: none;
  border: none;
  border-radius: 0 1rem 0 4px;
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 24px;
  display: flex;
  position: absolute;
  top: 1px;
  right: 1px;
}

.dropzone .remove:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #9acd32;
}

.input-group {
  gap: .5rem;
  margin-bottom: .5rem;
  display: flex;
}

.input-group label {
  width: 100%;
}

.input-group input {
  border: 1px solid #666;
  border-radius: 4px;
}

.input-group input:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #9acd32;
}
/*# sourceMappingURL=index.d25a3abd.css.map */
