html,
body {
  margin: 0;
}

body {
  font-family: sans-serif;
  color: #000;
  background: #e3e3e3;
}

a:link,
a:visited {
  color: green;
  transition: all 0.15s ease-in-out;
}

a:hover {
  color: darkblue;
}

a:active {
  color: rebeccapurple;
}

a:focus-visible {
  outline: 2px dashed yellowgreen;
  outline-offset: 1px;
}

a[rel='external'] {
  position: relative;

  &::after {
    content: '⎋';
    position: absolute;
    top: -12px;
    right: -10px;
    display: inline-flex;
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #666;
    transform: scaleX(-1);
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  &:hover,
  &:focus-visible {
    &::after {
      opacity: 1;
    }
  }
}

::selection {
  background-color: greenyellow;
  color: #000;
}

* {
  box-sizing: border-box;
}

#app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.hello {
  position: relative;
  min-width: 20rem;
  max-width: calc(100vw - 2rem);
  width: fit-content;
  overflow: hidden;
  margin: 0 auto;
  padding: 1rem;
  font-size: 1.1rem;
  border-radius: 1rem;
  background-color: #000;
  font-family: 'Fira Code', monospace;
  color: greenyellow;
  outline: 0.5rem inset #ccc;
  text-shadow: 0 0 4px rgba(greenyellow, 0.6);
  // caret-color: greenyellow;
  // caret-shape: block;
  caret-color: transparent;

  @media (min-width: 1024px) {
    min-width: 33vw;
    max-width: 66vw;
    min-height: 33vh;
    max-height: 90vh;
    margin-top: 10vh;
    padding: 2rem;
    font-size: 1.2rem;
  }

  * {
    display: inline;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTI4MCIgaGVpZ2h0PSI4MzIiIHZpZXdCb3g9IjAgMCAxMjgwIDgzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjEyODAiIGhlaWdodD0iODMyIiBmaWxsPSJ1cmwoI3BhaW50MF9yYWRpYWxfMzM3XzMpIi8+CjxkZWZzPgo8cmFkaWFsR3JhZGllbnQgaWQ9InBhaW50MF9yYWRpYWxfMzM3XzMiIGN4PSIwIiBjeT0iMCIgcj0iMSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIGdyYWRpZW50VHJhbnNmb3JtPSJyb3RhdGUoMzMuMDIzOSkgc2NhbGUoMTUyNi42NCAyMzQ4LjY3KSI+CjxzdG9wIHN0b3AtY29sb3I9IndoaXRlIiBzdG9wLW9wYWNpdHk9IjAuMzMiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLW9wYWNpdHk9IjAiLz4KPC9yYWRpYWxHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K'),
      repeating-linear-gradient(
        transparent,
        transparent 3px,
        #00000050 3px,
        #00000050 4px
      );
    background-size: cover;
    border-radius: 1rem;
    pointer-events: none;
  }

  &:focus-within:after {
    content: '█';
    animation: blonk 1s infinite;
  }
}

.introduction {
  margin-bottom: 4rem;
  font-size: 1.2rem;
}

@keyframes blonk {
  0% {
    color: #000;
  }
  50% {
    color: greenyellow;
  }
}

// _forms.scss

fieldset {
  padding: 1.5rem 1rem 1rem;
  border: 1px solid #999;
  border-radius: 4px;

  legend {
    position: relative;
    top: 14px;
    padding: 0.25rem 0.5rem;
    margin-top: 13px;
    margin-left: -1rem;
    border: 1px solid #999;
    border-radius: 4px 0 4px 0;
    background-color: #999;
    color: #e3e3e3;
  }
}

textarea {
  padding: 1rem;
  border: 1px solid #666;
  border-radius: 4px;

  &:focus-visible {
    outline: 2px solid yellowgreen;
    outline-offset: 1px;
  }
}

select {
  border: 1px solid #666;
  border-radius: 4px;

  &:focus-visible {
    outline: 2px solid yellowgreen;
    outline-offset: 1px;
  }
}

.checkbox-field {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  line-height: 1.1;

  input[type='checkbox'] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 1px solid #333;
    border-radius: 4px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid yellowgreen;
      outline-offset: 1px;
    }

    &::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em green;
      transform-origin: 50%;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:checked::before {
      transform: scale(1);
    }
  }
}

.dropzone {
  position: relative;
  margin-bottom: 2rem;
  border: 1px dashed #333;
  border-radius: 1rem;

  &:has(input[type='file']:focus-visible) {
    outline: 2px solid yellowgreen;
    outline-offset: 1px;
  }

  label {
    display: block;
    padding: 1rem;
    text-align: center;
  }

  input[type='file'] {
    position: absolute;
    inset: 0;
    opacity: 0;
  }

  &.has-file {
    border-color: green;
  }

  .file-name {
    margin-top: 0;
    text-align: center;
  }

  .remove {
    position: absolute;
    display: flex;
    width: 24px;
    height: 24px;
    font-size: 24px;
    border: none;
    background: none;
    top: 1px;
    right: 1px;
    padding: 0;
    color: indianred;
    border-radius: 0 1rem 0 4px;

    &:focus-visible {
      outline: 2px solid yellowgreen;
      outline-offset: 1px;
    }
  }
}

.input-group {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  label {
    width: 100%;
  }

  input {
    // padding: 1rem;
    border: 1px solid #666;
    border-radius: 4px;

    &:focus-visible {
      outline: 2px solid yellowgreen;
      outline-offset: 1px;
    }
  }
}
